import { Suspense } from 'react'
import AppLayout from 'layouts/appLayout'
import ReportLayout from 'layouts/reportLayout'
import RequireAuth from 'components/RequireAuth'

import { Routes, Route } from 'react-router-dom'

import CircularIndeterminate from 'components/CircularIndeterminate'

import Main from './views/main'
import Dashboard from './views/dashboard/Dashboard'
// import User from './views/user/User'
import CmmnCode from './views/cmmnCode/CmmnCode'
// import Settings from './views/settings/Settings'
// import Join from './views/user/Join'
// import FindAccount from './views/user/FindAccount'
import Notice from './views/board/Notice'
// import Guide from './views/board/Guide'
import NotFound from './views/error/notFound'
import Unauthorized from './views/unauthorized/Unauthorized'
import Employee from 'views/employee/Employee'
// import MyInfo from 'views/user/MyInfo'
// import BlankPage from 'views/template/BlankPage'
import ResultOld from 'views/test/ResultOld'
import ResultOldV11 from 'views/test/ResultOldV11'
import DownloadType from 'views/report/DownloadType'

import TestItem from 'views/test/item'
import TestCovid from 'views/test/covid'
// import TestHistory from 'views/test/history'
import TestOld from 'views/test/old'
import TestSyncOld from 'views/test/syncOld'

// import StatItem from 'views/stat/item'
// import StatResult from 'views/stat/result'
// import StatDelay from 'views/stat/delay'
// import StatAdditional from 'views/stat/addtional'
// import StatTolerance from 'views/stat/tolerance'
// import StatBlood from 'views/stat/blood'

// import InfectionTuberculosis from 'views/infection/tuberculosis'
// import InfectionPathogen from 'views/infection/pathogen'
// import InfectionSearch from 'views/infection/search'

// import EtcSearch from 'views/etc/search'
// import EtcSms from 'views/etc/sms'
// import EtcSales from 'views/etc/sales'
// import EtcInvoice from 'views/etc/invoice'
// import EtcInnopay from 'views/etc/innopay'
// import EtcPhone from 'views/etc/phone'
// import EtcTalk from 'views/etc/talk'

import CovidReport from 'views/report/CovidReport'
import Disease from 'views/test/Disease'
import ReportText from 'views/report/ReportText'
import ReportFile from 'views/report/ReportFile'

import Result from 'views/test/Result'
import ResultV2 from 'views/test/ResultV2'

import Search from 'views/search/Search'

import ResultDownload from 'views/test/ResultDownload'

import Docs from 'views/docs/Docs'
import ExcelDownload from 'views/test/excelDownload'

// import Env from 'views/env'

import TestSync from 'views/test/sync'
import TestSyncUpload from 'views/test/syncUpload'
import TestSyncDownload from 'views/test/syncDownload'
import TestSyncDownload2 from 'views/test/syncDownload2'

import Print from 'views/document/Print'
import PrintCodeManage from 'views/document/PrintCodeManage'

import SendMessage from 'views/message/sendMessage'
import GroupManage from 'views/message/groupManage'
import ResvMessage from 'views/message/resvMessage'
import MessageResult from 'views/message/messageResult'
import ResvCheck from 'views/message/resvCheck'
import Address from 'views/message/address'
import Message from 'views/message/message'
import MessageNumber from 'views/message/messageNumber'
import SmsTemplate from 'views/message/smsTemplate'
import Template from 'views/message/template'
import SlideBlockPage from 'views/test/slideBlock'
import SbRequestPage from 'views/test/sbRequest'
import SlideBlockResultPage from 'views/test/SlideBlockResult'
import TatByItemPage from 'views/tat/tatByItem'
import TatByPatientPage from 'views/tat/tatByPatient'
import AccountLockPage from 'views/accountLock/accountLock'

import MedicinePage from 'views/statistics/medicine'
import BloodStatPage from 'views/statistics/bloodStat'
import BloodPage from 'views/statistics/blood'

import SmsClient from 'views/message/smsClient'

import BlankPage from 'views/template/BlankPage'

import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUIKEY}`)

const ROLES = {
	ROLE_USER: 1,
	ROLE_ADMIN: 3,
	ROLE_SYSTEM: 2,
	ROLE_SB: 4,
}

function App() {
	return (
		<Suspense fallback={<CircularIndeterminate />}>
			<Routes>
				<Route
					path="/"
					element={<Main />}
				/>
				{/* <Route
					path="/user/join"
					element={<Join />}
				/>
				<Route
					path="/user/findAccount"
					element={<FindAccount />}
				/> */}
				<Route
					path="/unauthorized"
					element={<Unauthorized />}
				/>
				<Route
					path="*"
					element={<NotFound />}
				/>
				<Route
					path="/report"
					element={
						<ReportLayout>
							<RequireAuth
								allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
							/>
						</ReportLayout>
					}
				>
					{/* <Route
						path="/report/A005"
						element={<A005 />}
					/>
					<Route
						path="/report/A150"
						element={<A150 />}
					/>
					<Route
						path="/report/A010"
						element={<A010 />}
					/>
					<Route
						path="/report/A146"
						element={<A146 />}
					/>
					<Route
						path="/report/A165"
						element={<A165 />}
					/>
					<Route
						path="/report/E2"
						element={<E2 />}
					/>
					<Route
						path="/report/A155"
						element={<A155 />}
					/>
					<Route
						path="/report/A139"
						element={<A139 />}
					/> */}
					{/* <Route
						path="/report/A139"
						element={<A139 />}
					/> */}
					<Route
						path="/report/covid"
						element={<CovidReport />}
					/>
					{/* <Route
						path="/report/A940"
						element={<A940 />}
					/>
					<Route
						path="/report/A300"
						element={<A300 />}
					/>
					<Route
						path="/report/A305"
						element={<A305 />}
					/>
					<Route
						path="/report/A310"
						element={<A310 />}
					/> */}
					<Route
						path="/report/text"
						element={<ReportText />}
					/>
				</Route>
				<Route
					path="/admin"
					element={
						<AppLayout>
							<RequireAuth
								allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
							/>
						</AppLayout>
					}
				>
					<Route
						path="/admin/b"
						element={<BlankPage />}
					/>
					<Route
						path="/admin/dashboard"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<Dashboard />
							</>
						}
					/>
					<Route
						path="/admin/search"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<Search />
							</>
						}
					/>
					{/* <Route
						path="/admin/user"
						element={
							<>
								<RequireAuth allowedRoles={[ROLES.ROLE_ADMIN]} />
								<User />
							</>
						}
					/> */}
					{/* <Route
						path="/admin/settings"
						element={<Settings />}
					/> */}
					<Route
						path="/admin/cmmnCode"
						element={
							<>
								<RequireAuth allowedRoles={[ROLES.ROLE_SYSTEM]} />
								<CmmnCode />
							</>
						}
					/>
					<Route
						path="/admin/accountLock"
						element={
							<>
								<RequireAuth allowedRoles={[ROLES.ROLE_SYSTEM]} />
								<AccountLockPage />
							</>
						}
					/>
					<Route
						path="/admin/docs"
						element={
							<>
								<RequireAuth allowedRoles={[ROLES.ROLE_SYSTEM]} />
								<Docs />
							</>
						}
					/>
					<Route
						path="/admin/notice"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<Notice />
							</>
						}
					/>
					{/* <Route
						path="/admin/guide"
						element={<Guide />}
					/> */}
					<Route
						path="/admin/employee"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<Employee />
							</>
						}
					/>
					{/* <Route
						path="/admin/myInfo"
						element={<MyInfo />}
					/> */}
					<Route
						path="/admin/downloadType"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<DownloadType />
							</>
						}
					/>
					<Route
						path="/admin/reportFile"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<ReportFile />
							</>
						}
					/>
					<Route
						path="/admin/test/result"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<Result />
							</>
						}
					/>
					<Route
						path="/admin/test/resultV2"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<ResultV2 />
							</>
						}
					/>

					<Route
						path="/admin/test/resultOld"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<ResultOld />
							</>
						}
					/>
					<Route
						path="/admin/test/resultOldV11"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<ResultOldV11 />
							</>
						}
					/>

					<Route
						path="/admin/test/resultDownload"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<ResultDownload />
							</>
						}
					/>
					<Route
						path="/admin/test/disease"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<Disease />
							</>
						}
					/>
					<Route
						path="/admin/test/item"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<TestItem />
							</>
						}
					/>
					<Route
						path="/admin/test/covid"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<TestCovid />
							</>
						}
					/>
					<Route
						path="/admin/test/excelDownload"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<ExcelDownload />
							</>
						}
					/>
					{/* <Route
						path="/admin/test/history"
						element={<TestHistory />}
					/>*/}
					<Route
						path="/admin/test/old"
						element={<TestOld />}
					/>
					<Route
						path="/admin/test/slideBlock"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<SlideBlockPage />
							</>
						}
					/>
					<Route
						path="/admin/test/sbResult"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<SlideBlockResultPage />
							</>
						}
					/>
					<Route
						path="/admin/sbRequest"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_SB, ROLES.ROLE_USER, ROLES.ROLE_SYSTEM]}
								/>
								<SbRequestPage />
							</>
						}
					/>
					<Route
						path="/admin/test/syncOld"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<TestSyncOld />
							</>
						}
					/>
					<Route
						path="/admin/test/sync"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<TestSync />
							</>
						}
					/>
					<Route
						path="/admin/test/syncUpload"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<TestSyncUpload />
							</>
						}
					/>
					<Route
						path="/admin/test/syncDownload"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<TestSyncDownload />
							</>
						}
					/>
					<Route
						path="/admin/test/syncDownload2"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<TestSyncDownload2 />
							</>
						}
					/>
					<Route
						path="/admin/tat/tatByPatient"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<TatByPatientPage />
							</>
						}
					/>
					<Route
						path="/admin/tat/tatByItem"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<TatByItemPage />
							</>
						}
					/>
					<Route
						path="/admin/document/print"
						element={
							<>
								<RequireAuth allowedRoles={[ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]} />
								<Print />
							</>
						}
					/>
					<Route
						path="/admin/document/printCodeManage"
						element={
							<>
								<RequireAuth allowedRoles={[ROLES.ROLE_SYSTEM]} />
								<PrintCodeManage />
							</>
						}
					/>
					<Route
						path="/admin/message/sendMessage"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<SendMessage />
							</>
						}
					/>
					<Route
						path="/admin/message/groupManage"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<GroupManage />
							</>
						}
					/>
					<Route
						path="/admin/message/resvMessage"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<ResvMessage />
							</>
						}
					/>
					<Route
						path="/admin/message/messageResult"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<MessageResult />
							</>
						}
					/>
					<Route
						path="/admin/message/resvCheck"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<ResvCheck />
							</>
						}
					/>
					<Route
						path="/admin/message/address"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<Address />
							</>
						}
					/>
					<Route
						path="/admin/message/message"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<Message />
							</>
						}
					/>
					<Route
						path="/admin/message/messageNumber"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<MessageNumber />
							</>
						}
					/>
					<Route
						path="/admin/message/template"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<Template />
							</>
						}
					/>
					<Route
						path="/admin/smsClient"
						element={
							<>
								<RequireAuth allowedRoles={[ROLES.ROLE_SYSTEM]} />
								<SmsClient />
							</>
						}
					/>
					<Route
						path="/admin/smsTemplate"
						element={
							<>
								<RequireAuth allowedRoles={[ROLES.ROLE_SYSTEM]} />
								<SmsTemplate />
							</>
						}
					/>
					<Route
						path="/admin/statistics/medicine"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<MedicinePage />
							</>
						}
					/>
					<Route
						path="/admin/statistics/bloodStat"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<BloodStatPage />
							</>
						}
					/>
					<Route
						path="/admin/statistics/blood"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<BloodPage />
							</>
						}
					/>
					{/* <Route
						path="/admin/stat/item"
						element={<StatItem />}
					/>
					<Route
						path="/admin/stat/result"
						element={<StatResult />}
					/>
					<Route
						path="/admin/stat/delay"
						element={<StatDelay />}
					/>
					<Route
						path="/admin/stat/additional"
						element={<StatAdditional />}
					/>
					<Route
						path="/admin/stat/tolerance"
						element={<StatTolerance />}
					/>
					<Route
						path="/admin/stat/blood"
						element={<StatBlood />}
					/>
					<Route
						path="/admin/infection/pathogen"
						element={<InfectionPathogen />}
					/>
					<Route
						path="/admin/infection/tuberculosis"
						element={<InfectionTuberculosis />}
					/>
					<Route
						path="/admin/infection/search"
						element={<InfectionSearch />}
					/>
					<Route
						path="/admin/etc/search"
						element={<EtcSearch />}
					/>
					<Route
						path="/admin/etc/sms"
						element={<EtcSms />}
					/>
					<Route
						path="/admin/etc/sales"
						element={<EtcSales />}
					/>
					<Route
						path="/admin/etc/invoice"
						element={<EtcInvoice />}
					/>
					<Route
						path="/admin/etc/innopay"
						element={<EtcInnopay />}
					/>
					<Route
						path="/admin/etc/phone"
						element={<EtcPhone />}
					/>
					<Route
						path="/admin/etc/talk"
						element={<EtcTalk />}
					/>
					<Route
						path="/admin/env"
						element={<Env />}
					/> */}
					{/* <Route
						path="/admin/etc/message"
						element={
							<>
								<RequireAuth
									allowedRoles={[ROLES.ROLE_USER, ROLES.ROLE_ADMIN, ROLES.ROLE_SYSTEM]}
								/>
								<Sse />
							</>
						}
					/> */}
				</Route>
				<Route
					path="*"
					element={<NotFound />}
				/>
			</Routes>
		</Suspense>
	)
}

export default App
