import { useEffect, useState, forwardRef } from 'react'
import { testItemList } from 'api/api'
import {
	Grid,
	Box,
	Button,
	TextField,
	Typography,
	Dialog,
	DialogContent,
	useMediaQuery,
	useTheme,
	styled,
} from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
	DataGridPremium,
	koKR,
	GridColDef,
	GridSelectionModel,
	GridRowModel,
	GridApi,
	useGridApiRef,
	GridDensity,
	GridValidRowModel,
	GridRowId,
} from '@mui/x-data-grid-premium'
import { valuesInIterator } from 'util/objectUtil'
import { getSettings } from 'helpers/storage'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	)
})

const columns: GridColDef[] = [
	{
		field: 'rowNum',
		headerName: '번호',
		type: 'number',
		width: 100,
		editable: false,
		hide: true,
	},
	{
		field: 'TestCode',
		headerName: '검사코드',
		type: 'string',
		width: 80,
		editable: false,
		hide: true,
	},
	{
		field: 'PartCode',
		headerName: '검사코드',
		type: 'string',
		width: 50,
		editable: false,
		hide: true,
	},
	{
		field: 'InsuranceCode',
		headerName: '보험코드',
		type: 'string',
		width: 80,
		editable: false,
	},
	{
		field: 'DisplayName',
		headerName: '검사명',
		type: 'string',
		width: 150,
		editable: false,
	},
]

const SearchConditionBox = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	flex: '0 1 auto',
	alignItems: 'center',
	'.MuiTextField-root': {
		width: '100%',
	},
	'.searchField': {
		flex: '1 0 auto',
	},
	'.wrap': {
		display: 'flex',
		marginLeft: '1rem',
		gap: '10px',
	},
}))

const TestItemSearch = ({ dialogOpen, dialogClose, popupSubmit }: any) => {
	const env = getSettings()
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	const [searchKeyword, setSearchKeyword] = useState<string>('')
	const [searchList, setSearchList] = useState<any[]>([])

	const popupSearchAction = () => {
		testItemList(searchKeyword).then((res) => {
			console.log(res)
			setSearchList(res)
		})
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) popupSearchAction()
	}

	// grid
	const apiRef = useGridApiRef()
	const apiRef2 = useGridApiRef()
	const [gridApi, setGridApi] = useState<GridApi | null>(null)
	const [gridSelectionModel, setGridSelectionModel] =
		useState<GridSelectionModel>([])
	const [gridRows, setGridRows] = useState<GridRowModel[]>([])
	const [gridLoading, setGridLoading] = useState(false)
	const [gridPageSize, setGridPageSize] = useState(env.row ? env.row : 100)
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])
	const [checkLength, setCheckLength] = useState<number>(0)

	const [selectedRow, setSelectedRow] = useState<any>([])

	const onRowClick = (e: GridRowModel) => {
		const selectedRows: Map<GridRowId, GridValidRowModel> =
			apiRef.current.getSelectedRows()
		const selectedRowsArray = valuesInIterator(selectedRows)
		console.log(selectedRowsArray)
		setCheckLength(selectedRowsArray.length)
	}

	const transferToRight = () => {
		if (apiRef.current.getSelectedRows().size > 0) {
			const selectedRows: Map<GridRowId, GridValidRowModel> =
				apiRef.current.getSelectedRows()

			const selectedRowsArray = valuesInIterator(selectedRows)

			// selectedRow에 추가활때 중복은 제외한다.
			const tempRowsArray = valuesInIterator(selectedRow)
			const tempRowNums = tempRowsArray.map((row: any) => row.rowNum)
			const newRows = selectedRowsArray.filter(
				(row: any) => !tempRowNums.includes(row.rowNum)
			)
			setSelectedRow([...tempRowsArray, ...newRows])

			// setSelectedRow(selectedRowsArray)
			setSelectionModel([])
		}
	}

	const formSubmit = () => {
		popupSubmit(selectedRow)
	}

	useEffect(() => {}, [selectedRow])

	return (
		<Dialog
			fullScreen={isMdUp ? false : true}
			open={dialogOpen}
			onClose={dialogClose}
			TransitionComponent={Transition}
			maxWidth="lg"
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar variant="dense">
					<Typography
						sx={{ ml: 2, flex: 1 }}
						variant="h6"
						component="div"
					>
						검사항목 코드조회
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={dialogClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent>
				<Box
					component="div"
					onKeyUp={onKeyUp}
				>
					<SearchConditionBox>
						<div className="searchField">
							<TextField
								label="검색어"
								variant="outlined"
								value={searchKeyword}
								size="small"
								onChange={(e) => setSearchKeyword(e.target.value)}
							/>
						</div>
						<div className="wrap">
							<div className="">
								<Button
									color="primary"
									variant="contained"
									//startIcon={<ManageSearchIcon />}
									onClick={popupSearchAction}
								>
									조회
								</Button>
							</div>
							<div className="">
								<Button
									color="info"
									variant="contained"
									//startIcon={<DoneOutlineIcon />}
									onClick={formSubmit}
									disabled={selectedRow.length === 0}
								>
									선택완료
								</Button>
							</div>
						</div>
					</SearchConditionBox>
					<Grid
						container
						spacing={1}
						sx={{ mt: 1 }}
					>
						<Grid
							item
							sm={5}
							xs={12}
						>
							<div style={{ height: 400, width: isMdUp ? '250px' : '100%' }}>
								<DataGridPremium
									apiRef={apiRef}
									rows={searchList}
									columns={columns}
									getRowId={(row) => row.rowNum}
									pageSize={gridPageSize}
									onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
									// rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
									pagination
									checkboxSelection
									selectionModel={selectionModel}
									onSelectionModelChange={(newSelectionModel) => {
										setSelectionModel(newSelectionModel)
									}}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									density={(env.desnse as GridDensity) || 'standard'}
								/>
							</div>
						</Grid>
						<Grid
							item
							sm={2}
							xs={12}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{isMdUp ? (
								<Button
									aria-label="선택"
									// disabled={apiRef.current.getSelectedRows().size === 0}
									color="secondary"
									size="large"
									onClick={transferToRight}
									variant="contained"
									sx={{ ml: 1 }}
								>
									<KeyboardArrowRightIcon />
								</Button>
							) : (
								<Button
									aria-label="선택"
									disabled={checkLength === 0}
									color="secondary"
									size="large"
									onClick={transferToRight}
									variant="contained"
								>
									<KeyboardArrowDownIcon />
								</Button>
							)}
						</Grid>
						<Grid
							item
							sm={5}
							xs={12}
						>
							<div style={{ height: 400, width: isMdUp ? '250px' : '100%' }}>
								<DataGridPremium
									apiRef={apiRef2}
									rows={selectedRow}
									columns={[
										{
											field: 'rowNum',
											headerName: '번호',
											type: 'number',
											width: 100,
											editable: false,
											hideable: true,
											hide: true,
										},
										{
											field: 'TestCode',
											headerName: '검사코드',
											type: 'string',
											width: 200,
											editable: false,
											hideable: true,
											hide: true,
										},
										{
											field: 'InsuranceCode',
											headerName: '보험코드',
											type: 'string',
											width: 80,
											editable: false,
										},
										{
											field: 'DisplayName',
											headerName: '검사명',
											type: 'string',
											width: 150,
											editable: false,
										},
										{
											field: 'del',
											headerName: '삭제',
											type: 'string',
											width: 80,
											editable: false,
											renderCell: (params) => {
												const onClick = (e: any) => {
													e.stopPropagation()
													const newRows = selectedRow.filter(
														(row: any) => row.rowNum !== params.row.rowNum
													)
													setSelectedRow(newRows)
													// const temp = searchList.filter(
													// 	(row: any) => row.rowNum !== params.row.rowNum
													// )
													// setSearchList(temp)
												}
												return (
													<Button
														variant="contained"
														size="small"
														color="error"
														onClick={onClick}
													>
														삭제
													</Button>
												)
											},
										},
									]}
									getRowId={(row) => row.rowNum}
									pageSize={gridPageSize}
									onPageSizeChange={(newPageSize) => setGridPageSize(newPageSize)}
									// rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
									pagination
									// onRowClick={onRowClick}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									density={(env.desnse as GridDensity) || 'standard'}
								/>
							</div>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	)
}

export default TestItemSearch
