import { styled, useTheme } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { hexToRgba } from 'util/util'

export const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

export const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

export const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

export const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

export const SearchRowItem = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'block',
	paddingLeft: '80px',
	minHeight: '44px',
	marginBottom: '10px',
	'&.noLabel': {
		paddingLeft: '0',
	},
	'.labelText': {
		position: 'absolute',
		left: '10px',
		top: '15px',
		[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
			position: 'relative',
			left: '0',
			top: '0',
			marginBottom: '0.5rem',
		},
	},
	[`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
		paddingLeft: '0',
		height: 'auto',
	},
}))
