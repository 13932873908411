import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import {
	Stack,
	Grid,
	Paper,
	Box,
	Button,
	TextField,
	MenuItem,
	Typography,
	LinearProgress,
	useMediaQuery,
	Breadcrumbs,
	FormControl,
	InputLabel,
	Select,
	SelectChangeEvent,
} from '@mui/material'

import {
	DataGridPremium,
	koKR,
	GridRowModel,
	GridDensity,
	useGridApiRef,
	GridToolbar,
	GridSelectionModel,
} from '@mui/x-data-grid-premium'
import { styled, useTheme } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import { selectuserInfo } from 'hooks/userInfo'
import { getSettings } from 'helpers/storage'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import {
	alertModal,
	compareDate,
	hexToRgba,
	htmlAlertModal,
	isValidDateType,
} from 'util/util'

import { apiTatListByPatientName } from 'api/api'
import ClientSearchPopup from 'views/test/clientSearchPopup'

import { PageBg } from 'components/ComponentStyles'

import moment from 'moment'
import 'moment/locale/ko'
import DownloadIcon from '@mui/icons-material/Download'
import { PrevReceptionSearchType } from 'constants/types'
import TestItemSearch from 'components/TestItemSearch'

import { selectCmmnCode } from 'hooks/cmmnCodeFactory'
import { extractCmmnCode } from 'util/cmmnCodeUtil'

const locales = ['ko'] as const

const PageBg2 = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '1rem',
	borderRadius: '0.5rem',
	background: `${hexToRgba(theme.palette.custom.wrapper, 1)}`,
	// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		flexDirection: 'column',
		borderRadius: 0,
	},
	// backdropFilter: 'blur(10px)',
}))

const PageWrap = styled('div')(({ theme }) => ({
	marginBottom: '1rem',
	position: 'relative',
	top: '0',
	left: '0',
	right: '0',
	zIndex: '110',
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		//marginLeft: '-1.5rem',
		//marginRight: '-1.5rem',
		top: '0',
		//borderRadius: '0',
	},
}))

const BtnBox = styled(Box)(({ theme }) => ({
	// [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
	// 	marginTop: '1rem',
	// },
}))

const TitleText = styled(Typography)(({ theme }) => ({
	[`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
		display: 'none',
	},
}))

const TatByPatient = () => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

	const userInfo = useSelector(selectuserInfo)
	// 공통코드 redux store
	const cmmnCdList = useSelector(selectCmmnCode)

	const env = getSettings()

	const gridRef = useGridApiRef()

	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	// ui state
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	// 검색 조건
	const [dateRange, setDateRange] = useState('7d')
	const [search, setSearch] = useState<PrevReceptionSearchType>({
		clientID: userInfo.infoType === 'C' ? userInfo.clientID : '',
		startDate: moment(new Date()).isBefore('2023-08-01')
			? moment('2023-08-01').format('yyyy-MM-DD')
			: moment().subtract(7, 'days').format('YYYY-MM-DD'),
		endDate: moment().format('YYYY-MM-DD'),
		searchTestCd: '',
		patientName: '',
		chartNo: '',
		receptionRegNum: '',
		socialNumber: '',
		testCodes: '',
		searchType: 'R',
	})
	const [testNames, setTestNames] = useState<string>('')
	// 조회조건 (검체종류)
	const [testCdList, setTestCdList] = useState<any>([])

	const actionReset = () => {
		setSearch({
			clientID: userInfo.infoType === 'C' ? userInfo.clientID : '',
			startDate: moment(new Date()).isBefore('2023-08-01')
				? moment('2023-08-01').format('yyyy-MM-DD')
				: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			searchTestCd: '',
			patientName: '',
			chartNo: '',
			receptionRegNum: '',
			socialNumber: '',
			testCodes: '',
			searchType: 'R',
		})
		setTestNames('')
		setDateRange('7d')
		setSearchClientName('')
		gridRef.current?.setQuickFilterValues([])
		gridRef.current?.setPage(0)
	}

	const actionSearch = () => {
		let diffValue = compareDate(search.endDate, search.startDate)
		let res = false
		if (diffValue > 365) res = true
		diffValue = compareDate(search.startDate, search.endDate)
		if (diffValue > 365) res = true
		if (
			search.searchTestCd === '0' &&
			search.patientName === '' &&
			search.chartNo === '' &&
			search.receptionRegNum === '' &&
			search.socialNumber === '' &&
			search.testCodes === '' &&
			res
		) {
			htmlAlertModal(
				'검색기간이 <span style="color: red">12개월</span>을 초과할 경우<br/><span style="color: red">조회종류(수진자명, 검사항목)</span>을<br/>변경하시고 조회 부탁드립니다.',
				'error',
				() => {}
			)
			return
		}
		setGridMstLoading(true)
		const request = {
			...search,
			searchTestCd: search.searchTestCd !== '0' ? search.searchTestCd : '',
		}
		apiTatListByPatientName(request)
			.then((res) => {
				console.log(res)
				setRows(res)
			})
			.finally(() => {
				setGridMstLoading(false)
			})
	}

	// grid state
	const mstRef = useGridApiRef()
	const [rows, setRows] = useState([])
	const [gridMstLoading, setGridMstLoading] = useState(false)
	const [gridMstPageSize, setGridMstPageSize] = useState(env.row ? env.row : 100)

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		console.log(e)
		setSearchClientName(e.ClientName)
		setSearch({
			...search,
			clientID: e.ClientID,
		})
		setClientDialogOpen(false)
	}

	// grid row click event

	const [showSearchPopup, setShowSearchPopup] = useState(false)
	const [dialogOpen, setDialogOpen] = useState(false)
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

	const popupOpenAction = () => {
		setShowSearchPopup(true)
		setDialogOpen(true)
	}

	const dialogClose = () => {
		setDialogOpen(false)
	}

	// 검사항목 삭제 버튼 처리
	const handleDelete = () => {
		setTestNames('')
		setSearchClientName('')
		if (userInfo.infoType === 'S') {
			setSearch({
				...search,
				clientID: '',
				testCodes: '',
			})
		} else {
			setSearch({
				...search,
				testCodes: '',
			})
		}
	}

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '7d':
				getBeforeDate(7)
				break
			case '15d':
				getBeforeDate(15)
				break
			case '1m':
				getBeforeDate(30)
				break
			case '3m':
				getBeforeDate(90)
				break
			case '6m':
				getBeforeDate(180)
				break
			case '1y':
				getBeforeDate(365)
				break
			default:
				break
		}
		setDateRange(event.target.value)
		setDisabledSearch(false)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		// 파라미터로 받은 e 값과 시작일 또는 파라미터로 받은  e 값과 종료일 비교하여 날짜 차이를 구함
		let diffValue = compareDate(e, search.endDate)
		let res = false
		if (diffValue > 365) res = true
		diffValue = compareDate(search.startDate, e)
		if (diffValue > 365) res = true
		if (
			search.searchTestCd === '0' &&
			search.patientName === '' &&
			search.chartNo === '' &&
			search.receptionRegNum === '' &&
			search.socialNumber === '' &&
			search.testCodes === '' &&
			res
		) {
			htmlAlertModal(
				'검색기간이 <span style="color: red">12개월</span>을 초과할 경우<br/><span style="color: red">조회종류(수진자명, 검사항목)</span>을<br/>변경하시고 조회 부탁드립니다.',
				'error',
				() => {}
			)
			return
		}
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setSearch({
				...search,
				startDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, search.endDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, search.startDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setSearch((prevState) => {
					return {
						...search,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	/**
	 * 검체종류 select
	 * @param e
	 */
	const searchTestCdOnChange = (e: SelectChangeEvent<string>): void => {
		setSearch({
			...search,
			searchTestCd: e.target.value,
		})
	}

	/**
	 * 팝업에서 검사항목 선택시 처리
	 * @param arr
	 */
	const popupSubmit = (arr: []) => {
		setDialogOpen(false)
		const testCodes: string[] = arr.map((item: any) => item.TestCode)
		const testNames = arr.map((item: any) => item.DisplayName)

		const testCodesToString = testCodes.map((item) => item).join(',')

		setSearch({
			...search,
			testCodes: testCodesToString,
		})
		setTestNames(testNames.join(', '))
	}

	const actionExcelDownload = () => {
		// grid to excel
		setSelectionModel([])
		setTimeout(() => {
			mstRef.current?.exportDataAsExcel()
		}, 500)
	}

	useEffect(() => {
		// 검색조건의 공통코드
		const lv2 = extractCmmnCode(cmmnCdList, 'TEST_CD', 1)
		setTestCdList(lv2?.level2)
		// 검체 종류의 셀렉트박스 기초 value
		setSearch({
			...search,
			searchTestCd: '0',
		})
		if (userInfo?.infoType === 'C') actionSearch()
	}, [])

	return (
		<>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<TestItemSearch
				dialogOpen={dialogOpen}
				dialogClose={dialogClose}
				popupSubmit={popupSubmit}
			/>
			<Stack
				sx={{
					position: 'sticky',
					top: '50px',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2 sx={{ padding: '0.3rem 0.3rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								수진자별 TAT 조회
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<div>
								<span>
									<Button
										size="small"
										color="success"
										variant="contained"
										startIcon={isMdUp && <RotateLeftIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledReset}
										onClick={actionReset}
									>
										초기화
									</Button>
								</span>
								<span>
									<Button
										size="small"
										color="primary"
										variant="contained"
										startIcon={isMdUp && <ContentPasteSearchTwoToneIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledSearch}
										onClick={actionSearch}
									>
										조회
									</Button>
								</span>
								<span>
									<Button
										variant="contained"
										color="secondary"
										size="small"
										startIcon={<DownloadIcon />}
										onClick={actionExcelDownload}
									>
										엑셀 다운로드
									</Button>
								</span>
							</div>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				<Box
					component="form"
					noValidate
					autoComplete="off"
					onKeyUp={onKeyUp}
					sx={{
						borderTop:
							theme.palette.mode === 'dark'
								? '1px solid rgba(81, 81, 81, 1)'
								: '1px solid #ccc',
						paddingTop: '1rem',
					}}
				>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={locale}
					>
						<Grid
							container
							spacing={1}
						>
							<Grid
								item
								xs={6}
								sm={2}
								lg={3}
							>
								<FormControl fullWidth>
									<InputLabel id="searchType-select-label">기간 유형</InputLabel>
									<Select
										labelId="searchType-select-label"
										id="searchTypee-simple-select"
										value={search.searchType}
										label="기간 유형"
										onChange={(e) => {
											setSearch({
												...search,
												searchType: e.target.value,
											})
										}}
										size={'small'}
									>
										<MenuItem value={'R'}>접수일자</MenuItem>
										<MenuItem value={'D'}>보고일자</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid
								item
								xs={6}
								sm={3}
								lg={3}
							>
								<DatePicker
									label="시작일"
									value={search.startDate}
									mask={'____-__-__'}
									inputFormat={'YYYY-MM-DD'}
									onChange={(newValue) => {
										dateRanageOnChange(newValue, 'startDate')
									}}
									renderInput={(params) => (
										<TextField
											size="small"
											{...params}
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								sm={3}
								lg={3}
							>
								<DatePicker
									label="종료일"
									value={search.endDate}
									mask={'____-__-__'}
									inputFormat={'YYYY-MM-DD'}
									onChange={(newValue) => {
										dateRanageOnChange(newValue, 'endDate')
									}}
									renderInput={(params) => (
										<TextField
											size="small"
											{...params}
											fullWidth
										/>
									)}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								sm={3}
								lg={3}
							>
								<FormControl fullWidth>
									<InputLabel id="date-range-select-label">조회 기간</InputLabel>
									<Select
										labelId="date-range-select-label"
										id="date-range-simple-select"
										value={dateRange}
										label="조회 기간"
										onChange={searchDateRanageOnChange}
										size={'small'}
									>
										<MenuItem value={'0'}>선택하세요.</MenuItem>
										<MenuItem value={'7d'}>7일</MenuItem>
										<MenuItem value={'15d'}>15일</MenuItem>
										<MenuItem value={'1m'}>1개월</MenuItem>
										<MenuItem value={'3m'}>3개월</MenuItem>
										<MenuItem value={'6m'}>6개월</MenuItem>
										<MenuItem value={'1y'}>1년</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={1}
							sx={{ marginTop: '0.1rem' }}
						>
							<Grid
								item
								xs={6}
								sm={3}
								lg={3}
							>
								<FormControl fullWidth>
									<InputLabel id="date-range-select-label">학부(파트)</InputLabel>
									<Select
										labelId="date-range-select-label"
										id="date-range-simple-select"
										label="학부(파트)"
										value={search.searchTestCd}
										onChange={searchTestCdOnChange}
										size={'small'}
									>
										<MenuItem value={'0'}>전체</MenuItem>
										{testCdList &&
											testCdList.length > 0 &&
											testCdList.map(
												(item: { id: number; cmmnCd: string; cmmnNm: string }) => (
													<MenuItem
														key={item.id}
														value={item?.cmmnCd}
													>
														{item?.cmmnNm}
													</MenuItem>
												)
											)}
									</Select>
								</FormControl>
							</Grid>
							<Grid
								item
								xs={6}
								sm={3}
								lg={3}
							>
								<TextField
									label="수진자명"
									variant="outlined"
									size="small"
									type="text"
									fullWidth
									value={search.patientName}
									onChange={(e) => {
										setSearch({
											...search,
											patientName: e.target.value,
										})
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								lg={6}
							>
								<div style={{ display: 'flex' }}>
									<TextField
										label="검사항목"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										aria-readonly={true}
										value={testNames}
										onClick={popupOpenAction}
									/>
									<Button
										type="button"
										variant="contained"
										sx={{ width: '100px', ml: 1 }}
										onClick={handleDelete}
									>
										항목 삭제
									</Button>
								</div>
							</Grid>
							{(userInfo.infoType === 'S' || userInfo.infoType === 'W') && (
								<Grid
									item
									xs={12}
									sm={3}
									lg={3}
								>
									<TextField
										label="거래처"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
										aria-readonly={true}
										value={searchClientName}
										onClick={clientPopupOpenAction}
									/>
								</Grid>
							)}
						</Grid>
					</LocalizationProvider>
				</Box>
			</Stack>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden', mt: 2 }}
			>
				<Grid container>
					<Grid
						item
						xs={12}
					>
						<PageBg>
							<Box
								sx={{
									height: 'calc(100vh - 300px)',
									'@media (max-width: 1199px)': {
										height: '400px',
										overflow: 'auto',
									},
								}}
							>
								<DataGridPremium
									rows={rows}
									apiRef={mstRef}
									experimentalFeatures={{ newEditingApi: true }}
									disableMultipleSelection={true}
									//getRowId={(row) => row.rowNum}
									columns={[
										{
											field: 'id',
											headerName: 'id',
											type: 'number',
											minWidth: 50,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'ReceptionDate',
											headerName: '접수일자',
											minWidth: 100,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'ReceptionRegNum',
											headerName: '접수번호',
											minWidth: 100,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'PatientName',
											headerName: '수검자명',
											minWidth: 100,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'ChartNo',
											headerName: '차트번호',
											minWidth: 100,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'TestCode',
											headerName: '검사코드',
											minWidth: 100,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'DisplayName',
											headerName: '검사명',
											minWidth: 200,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'TATHour',
											headerName: 'TAT(시간)',
											minWidth: 100,
											editable: false,
											headerAlign: 'center',
											align: 'right',
										},
										{
											field: 'B10',
											headerName: '검사시작일시',
											minWidth: 160,
											editable: false,
											headerAlign: 'center',
										},
										{
											field: 'D90',
											headerName: '최종보고일시',
											minWidth: 160,
											editable: false,
											headerAlign: 'center',
										},
									]}
									pageSize={gridMstPageSize}
									onPageSizeChange={(newPageSize) => setGridMstPageSize(newPageSize)}
									rowsPerPageOptions={[5, 10, 20, 50, 100]}
									pagination
									autoHeight={false}
									localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
									loading={gridMstLoading}
									components={{
										Toolbar: GridToolbar,
										LoadingOverlay: LinearProgress,
									}}
									selectionModel={selectionModel}
									onSelectionModelChange={(newSelection) => {
										setSelectionModel(newSelection)
									}}
									componentsProps={{
										toolbar: {
											csvOptions: { disableToolbarButton: true },
											excelOptions: { disableToolbarButton: true },
											printOptions: { disableToolbarButton: true },
											showQuickFilter: false,
											quickFilterProps: { debounceMs: 250 },
										},
									}}
									density={(env.desnse as GridDensity) || 'compact'}
									initialState={{
										columns: {
											columnVisibilityModel: {
												ReceptionID: false,
												id: false,
											},
										},
									}}
									sx={{
										'& .MuiDataGrid-columnHeaderTitleContainer': {
											fontSize: '13px',
										},
										'& .MuiDataGrid-cell': {
											fontSize: '13px !important',
											borderRight:
												theme.palette.mode === 'dark'
													? '1px solid rgba(81, 81, 81, 1)'
													: '1px solid rgba(224, 224, 224, 1)',
										},
									}}
								/>
							</Box>
						</PageBg>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}
export default TatByPatient
