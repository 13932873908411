import {
	Box,
	Breadcrumbs,
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	Stack,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material'
import {
	BtnBox,
	PageBg2,
	PageWrap,
	SearchRowItem,
	TitleText,
} from 'components/FrameworkStyle'
import { getSettings } from 'helpers/storage'
import { selectuserInfo } from 'hooks/userInfo'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ClientSearchPopup from 'views/test/clientSearchPopup'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ko'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment'
import 'moment/locale/ko'
import { alertModal, compareDate, isValidDateType } from 'util/util'
import DownloadIcon from '@mui/icons-material/Download'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone'
import { PageBg } from 'components/ComponentStyles'
import {
	DataGridPremium,
	GridColDef,
	GridDensity,
	GridSelectionModel,
	GridToolbar,
	koKR,
	useGridApiRef,
} from '@mui/x-data-grid-premium'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import dayjs, { Dayjs } from 'dayjs'

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
)

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'ID',
		width: 100,
		headerAlign: 'center',
	},
	{
		field: 'a',
		headerName: '순번',
		width: 300,
		headerAlign: 'center',
	},
	{
		field: 'b',
		headerName: '접수일',
		width: 300,
		headerAlign: 'center',
	},
	{
		field: 'c',
		headerName: '접수번호',
		width: 300,
		headerAlign: 'center',
	},
	{
		field: 'd',
		headerName: '성명',
		width: 200,
		headerAlign: 'center',
	},
	{
		field: 'e',
		headerName: '차트번호',
		width: 200,
		headerAlign: 'center',
	},
	{
		field: 'f',
		headerName: '주민등록번호',
		width: 200,
		headerAlign: 'center',
	},
	{
		field: 'g',
		headerName: '성별',
		width: 200,
		headerAlign: 'center',
	},
	{
		field: 'h',
		headerName: '나이',
		width: 200,
		headerAlign: 'center',
	},
	{
		field: 'i',
		headerName: '생년월일',
		width: 200,
		headerAlign: 'center',
	},
	{
		field: 'j',
		headerName: '검채명',
		width: 200,
		headerAlign: 'center',
	},
	{
		field: 'k',
		headerName: '채혈량',
		width: 200,
		headerAlign: 'center',
	},
	{
		field: 'l',
		headerName: '기타',
		width: 200,
		headerAlign: 'center',
	},
]

const locales = ['ko'] as const

const Blood = () => {
	const theme = useTheme()
	const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))
	const userInfo = useSelector(selectuserInfo)
	const [locale, setLocale] = useState<(typeof locales)[number]>('ko')

	const env = getSettings()
	const [disabledSave, setDisabledSave] = useState(true)
	const [disabledDel, setDisabledDel] = useState(true)
	const [disabledSearch, setDisabledSearch] = useState(false)
	const [disabledReset, setDisabledReset] = useState(false)

	const [dateRange, setDateRange] = useState('1d')

	const [search, setSearch] = useState({
		clientID: 0,
		startDate: dayjs().format('YYYY-MM-DD'),
		endDate: dayjs().format('YYYY-MM-DD'),
		searchType: 'R',
	})

	const onKeyUp = (e: any) => {
		if (e.keyCode === 13) actionSearch()
	}

	const actionSearch = () => {}
	const actionReset = () => {}
	const actionExcelDownload = () => {
		setSelectionModel([])
		setTimeout(() => {
			mstRef.current?.exportDataAsExcel()
		}, 500)
	}

	const [prevResultList, setPrevResultList] = useState<any[]>([])

	// 내부사용자일 경우 거래처 선택 팝업
	const [clientDialogOpen, setClientDialogOpen] = useState(false)
	const [searchClientName, setSearchClientName] = useState('')
	const clientDialogClose = () => {
		setClientDialogOpen(false)
	}
	const clientPopupOpenAction = () => {
		setClientDialogOpen(true)
	}
	const clientPopupSubmit = (e: any) => {
		setSearchClientName(e.ClientName)
		setSearch({
			...search,
			clientID: e.ClientID,
		})
		setClientDialogOpen(false)
	}

	// calcaulate today before n days
	const getBeforeDate = (n: number) => {
		setSearch({
			...search,
			startDate: moment().subtract(n, 'days').format('YYYY-MM-DD'),
		})
	}

	const searchDateRanageOnChange = (event: any) => {
		switch (event.target.value) {
			case '1d':
				getBeforeDate(1)
				break
			case '3d':
				getBeforeDate(3)
				break
			case '7d':
				getBeforeDate(7)
				break
			default:
				break
		}
		setDateRange(event.target.value)
		setDisabledSearch(false)
	}

	// date picker on change event
	const dateRanageOnChange = (e: any, target: string) => {
		if (moment(new Date(e)).isBefore('2023-08-01')) {
			alertModal('2023-08-01 이전 날짜는 조회할 수 없습니다.', 'error', () => {})
			setSearch({
				...search,
				startDate: '2023-08-01',
			})
			return
		} else {
			if (moment(new Date(e)).format('yyyy-MM-DD').length === 10) {
				if (!isValidDateType(e)) {
					alertModal('날짜 형식이 올바르지 않습니다.', 'error', () => {})
					setDisabledSearch(true)
					return
				}

				if (target === 'startDate') {
					let diffValue = compareDate(e, search.endDate)
					if (diffValue > 0) {
						alertModal('시작일이 종료일보다 큽니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue < -1825) {
						alertModal('검색기간은 5년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				} else if (target === 'endDate') {
					let diffValue = compareDate(e, search.startDate)
					if (diffValue < 0) {
						alertModal('종료일이 시작일보다 작습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
					if (diffValue > 1825) {
						alertModal('검색기간은 1년을 초과할 수 없습니다.', 'error', () => {})
						setDisabledSearch(true)
						return
					}
				}

				setDisabledSearch(false)

				setSearch((prevState) => {
					return {
						...search,
						[target]: moment(new Date(e)).format('yyyy-MM-DD'),
					}
				})
			} else {
				setDisabledSearch(true)
			}
		}
	}

	// grid
	const mstRef = useGridApiRef()
	const [rows, setRows] = useState([])
	const [gridMstLoading, setGridMstLoading] = useState(false)
	const [gridMstPageSize, setGridMstPageSize] = useState(env.row ? env.row : 100)
	const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

	const options = {
		maintainAspectRatio: true,
		responsive: true,
		aspectRatio: 2,
		plugins: {
			legend: {
				position: 'top' as const,
				display: false,
			},
			title: {
				display: false,
				text: '이전 결과',
			},
		},
	}

	const labels = prevResultList.map((item: any) => {
		if (parseFloat(item.ResultData)) {
			return item.ReceptionDate
		}
	})

	const data = {
		labels,
		datasets: [
			{
				label: '결과',
				data: prevResultList.map((item: any) => {
					return parseFloat(item.ResultData)
				}),
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
			{
				label: '참고치(최저)',
				fill: false,
				backgroundColor: '#0000ff',
				borderColor: '#0000ff',
				borderDash: [3, 5],
				hidden: prevResultList[0]?.ref1Hidden,
				data: prevResultList.map((item: any) => {
					return parseFloat(item.ref1)
				}),
			},
			{
				label: '참고치(최대)',
				fill: false,
				backgroundColor: '#ff0000',
				borderColor: '#ff0000',
				borderDash: [3, 5],
				hidden: prevResultList[0]?.ref2Hidden,
				data: prevResultList.map((item: any) => {
					return parseFloat(item.ref2)
				}),
			},
		],
	}

	useEffect(() => {
		if (userInfo?.infoType === 'C') actionSearch()
	}, [])

	return (
		<>
			<ClientSearchPopup
				dialogOpen={clientDialogOpen}
				dialogClose={clientDialogClose}
				popupSubmit={clientPopupSubmit}
			/>
			<Stack
				sx={{
					position: 'sticky',
					top: '50px',
					zIndex: 100,
					padding: '0.5rem',
					borderRadius: '0.5rem',
					backgroundColor:
						theme.palette.mode === 'dark' ? 'rgba(50, 48, 54, 1)' : '#fff',
					'@media (max-width: 600px)': {
						position: 'relative',
						paddingTop: '15px',
						top: '0px',
					},
				}}
			>
				<PageWrap style={{ marginBottom: '0.5rem' }}>
					<PageBg2 sx={{ padding: '0.3rem 0.3rem 0rem 0.5rem' }}>
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="small" />}
							aria-label="breadcrumb"
							sx={{
								'@media (max-width: 900px': {
									display: 'none',
								},
							}}
						>
							<TitleText
								key="3"
								color="text.primary"
								sx={{ fontWeight: '500', fontSize: 17 }}
							>
								채혈량 통계
							</TitleText>
						</Breadcrumbs>
						<BtnBox>
							<div>
								<span>
									<Button
										size="small"
										color="success"
										variant="contained"
										startIcon={isMdUp && <RotateLeftIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledReset}
										onClick={actionReset}
									>
										초기화
									</Button>
								</span>
								<span>
									<Button
										size="small"
										color="primary"
										variant="contained"
										startIcon={isMdUp && <ContentPasteSearchTwoToneIcon />}
										sx={{
											marginRight: '0.5rem',
											padding: isMdUp ? '4px 10px' : '4px 6px',
											minWidth: isMdUp ? '64px' : '50px',
										}}
										disabled={disabledSearch}
										onClick={actionSearch}
									>
										조회
									</Button>
								</span>
								<span>
									<Button
										variant="contained"
										color="secondary"
										size="small"
										startIcon={<DownloadIcon />}
										onClick={actionExcelDownload}
									>
										엑셀 다운로드
									</Button>
								</span>
							</div>
						</BtnBox>
					</PageBg2>
				</PageWrap>
				<Box
					component="form"
					noValidate
					autoComplete="off"
					onKeyUp={onKeyUp}
					sx={{
						borderTop:
							theme.palette.mode === 'dark'
								? '1px solid rgba(81, 81, 81, 1)'
								: '1px solid #ccc',
						paddingTop: '1rem',
					}}
				>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						adapterLocale={locale}
					>
						<SearchRowItem>
							<p className="labelText">조회조건</p>
							<Grid
								container
								spacing={1}
							>
								<Grid
									item
									xs={6}
									sm={3}
									md={4}
								>
									<FormControl fullWidth>
										<InputLabel id="searchType-select-label">기간 유형</InputLabel>
										<Select
											labelId="searchType-select-label"
											id="searchTypee-simple-select"
											value={search.searchType}
											label="기간 유형"
											onChange={(e) => {
												setSearch({
													...search,
													searchType: e.target.value,
												})
											}}
											size={'small'}
										>
											<MenuItem value={'R'}>접수일자</MenuItem>
											<MenuItem value={'D'}>보고일자</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={4}
								>
									<DatePicker
										views={['year', 'month']}
										label="시작월"
										minDate={dayjs('2018-03-01')}
										maxDate={dayjs(new Date())}
										inputFormat="YYYY-MM"
										value={search.startDate}
										onChange={(newValue) => {
											setSearch({
												...search,
												startDate: newValue ? newValue.format('YYYY-MM') : search.startDate,
											})
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												helperText={null}
												size="small"
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={4}
								>
									<DatePicker
										views={['year', 'month']}
										label="종료월"
										minDate={dayjs('2018-03-01')}
										maxDate={dayjs(new Date())}
										inputFormat="YYYY-MM"
										value={search.startDate}
										onChange={(newValue) => {
											setSearch({
												...search,
												startDate: newValue ? newValue.format('YYYY-MM') : search.startDate,
											})
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												helperText={null}
												size="small"
												fullWidth
											/>
										)}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={4}
								>
									<TextField
										label="수진자명"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sm={3}
									md={4}
								>
									<TextField
										label="차트번호"
										variant="outlined"
										size="small"
										type="text"
										fullWidth
									/>
								</Grid>
								{(userInfo.infoType === 'S' || userInfo.infoType === 'W') && (
									<Grid
										item
										xs={6}
										sm={3}
										md={4}
									>
										<TextField
											label="거래처"
											variant="outlined"
											size="small"
											type="text"
											fullWidth
											aria-readonly={true}
											value={searchClientName}
											onClick={clientPopupOpenAction}
										/>
									</Grid>
								)}
							</Grid>
						</SearchRowItem>
					</LocalizationProvider>
				</Box>
			</Stack>
			<Paper
				elevation={0}
				sx={{ width: '100%', overflow: 'hidden', mt: 2 }}
			>
				<Grid container>
					<Grid
						item
						xs={12}
					>
						<PageBg>
							<Box
								sx={{
									height: 'calc(100vh - 300px)',
									'@media (max-width: 1199px)': {
										height: '400px',
										overflow: 'auto',
									},
								}}
							>
								<Box sx={{ height: '50%' }}>
									<div
										style={{
											backgroundColor: '#fff',
											padding: '1rem',
											width: '100%',
											height: '100%',
										}}
									>
										<Line
											options={options}
											data={data}
										/>
									</div>
								</Box>
								<Box sx={{ height: '50%', mt: 2 }}>
									<DataGridPremium
										rows={[]}
										columns={columns}
										pageSize={gridMstPageSize}
										onPageSizeChange={(newPageSize) => setGridMstPageSize(newPageSize)}
										rowsPerPageOptions={[5, 10, 20, 50, 100]}
										pagination
										autoHeight={false}
										loading={gridMstLoading}
										components={{
											Toolbar: GridToolbar,
											LoadingOverlay: LinearProgress,
										}}
										selectionModel={selectionModel}
										onSelectionModelChange={(newSelection) => {
											setSelectionModel(newSelection)
										}}
										localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
										componentsProps={{
											toolbar: {
												csvOptions: { disableToolbarButton: true },
												excelOptions: { disableToolbarButton: true },
												printOptions: { disableToolbarButton: true },
												showQuickFilter: false,
												quickFilterProps: { debounceMs: 250 },
											},
										}}
										density={(env.desnse as GridDensity) || 'compact'}
										initialState={{
											columns: {
												columnVisibilityModel: {
													ReceptionID: false,
													id: false,
												},
											},
										}}
										sx={{
											'& .MuiDataGrid-columnHeaderTitleContainer': {
												fontSize: '13px',
											},
											'& .MuiDataGrid-cell': {
												fontSize: '13px !important',
												borderRight:
													theme.palette.mode === 'dark'
														? '1px solid rgba(81, 81, 81, 1)'
														: '1px solid rgba(224, 224, 224, 1)',
											},
										}}
									/>
								</Box>
							</Box>
						</PageBg>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}
export default Blood
